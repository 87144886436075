import { ref } from 'vue'
import { Capacitor } from '@capacitor/core'
import { Camera, CameraResultType } from '@capacitor/camera'
import { Directory, Filesystem } from '@capacitor/filesystem'

import { isPlatform } from '@ionic/vue'

import httpClient from '@/core/api/api.service'
import helpers from '@/utils/helpers'

export default function usePhotoGallery() {
  const isLoading = ref(false)

  const takePhoto = async () => {
    try {
      const cameraPhoto = await Camera.getPhoto({
        resultType: CameraResultType.Uri,
        quality: 100,
        promptLabelHeader: 'Válassz a listából',
        promptLabelPhoto: 'Galéria',
        promptLabelPicture: 'Fotó készítés',
        promptLabelCancel: 'Mégse',
      })

      try {
        isLoading.value = true
        const fileName = `${new Date().getTime()}.jpeg`
        const savedFileImage = await savePicture(cameraPhoto, fileName)

        return Promise.resolve(savedFileImage)
      } catch (error) {
        return Promise.reject(error)
      } finally {
        isLoading.value = false
      }
    } catch (error) {
      return Promise.reject(error)
    }
  }

  const convertBlobToBase64 = blob =>
    new Promise((resolve, reject) => {
      const reader = new FileReader()

      reader.onerror = reject

      reader.onload = () => {
        resolve(reader.result)
      }

      reader.readAsDataURL(blob)
    })

  const savePicture = async (photo, fileName) => {
    let base64Data = null
    const image = {
      filepath: null,
      webviewPath: null,
      url: null,
    }

    if (isPlatform('hybrid')) {
      const file = await Filesystem.readFile({
        path: photo.path,
      })
      const blob = helpers.b64toBlob(file.data, `image/${photo.format}`)
      const { path } = await upload(blob)
      image.url = path
      base64Data = file.data
    } else {
      const response = await fetch(photo.webPath)
      const blob = await response.blob()
      const { path } = await upload(blob)
      image.url = path
      base64Data = await convertBlobToBase64(blob)
    }

    const savedFile = await Filesystem.writeFile({
      path: fileName,
      data: base64Data,
      directory: Directory.Data,
    })

    if (isPlatform('hybrid')) {
      image.filepath = savedFile.uri
      image.webviewPath = Capacitor.convertFileSrc(savedFile.uri)
    } else {
      image.filepath = fileName
      image.webviewPath = photo.webPath
    }

    return image
  }

  const upload = async file => {
    try {
      const formData = new FormData()
      formData.append('file', file)
      const { data } = await httpClient.upload('upload', '', formData, file.type)

      return Promise.resolve(data.data)
    } catch (error) {
      return Promise.reject(error)
    }
  }

  return {
    takePhoto,
    upload,
    isLoading,
  }
}
